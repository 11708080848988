<template>
  <div :class="[
      'video-player-wrapper',
      verticalFixed ? 'vertical-fixed' : 'horizontal',
      { 'adjust-height' : (screenAspectRatio < 0.5625) }
      ]">
    <video ref="video"
           :src="media[source]"
           playsinline
           :controls="controls && !verticalFixed">
    </video>

    <div v-if="showPlayButton && !isPlaying"
         class="big-play-button">
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from 'vuex';

export default defineComponent ({
  name: "VideoPlayer",

  props: {
    source: {
      type: String,
      required: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    verticalFixed: {
      type: Boolean,
      default: false
    },
    onEndRedirectTarget: {
      type: String,
      default: ''
    }
  },

  mounted() {
    const playerRef = this.$refs.video;

    // ios fullscreen
    if (this.playsinline) {
      playerRef.setAttribute('playsinline', this.playsinline.toString())
      playerRef.setAttribute('webkit-playsinline', this.playsinline.toString())
    }

    playerRef.addEventListener('play', () => {
      this.isPlaying = true;
    });
    playerRef.addEventListener('pause', () => {
      this.showPlayButton = this.verticalFixed;
      this.isPlaying = false;
    })

    if (this.onEndRedirectTarget) {
      playerRef.addEventListener('ended', () => {
        this.$router.push({
          path: this.onEndRedirectTarget
        })
      });
    }

    if (this.verticalFixed) {
      playerRef.addEventListener('click', this.handlePausePlay);
      playerRef.addEventListener('tap', this.handlePausePlay);
    }

    // handle autoplay
    if (this.verticalFixed || this.autoplay) {
      playerRef.play()
        .then(() => {
          // Autoplay started!
        }).catch(() => {
          // Autoplay prevented by Browser policy
          this.showPlayButton = this.verticalFixed;
      });
    }
  },

  unmounted() {
    this.dispose();
  },

  computed: {
    ...mapState(['media', 'routeCounter']),
    screenAspectRatio() {
      const offsetTop = 70; // TODO: dynamically calculate off.set
      return window.innerWidth / (window.innerHeight-offsetTop);
    },
  },

  data() {
    return {
      isPlaying: false,
      showPlayButton: false
    }
  },

  methods: {
    handlePausePlay() {
      if (this.$refs.video) {
        if (this.$refs.video.paused) {
          this.start();
        } else {
          this.stop();
        }
      }
    },
    dispose() {
      if (this.$refs.video) {
        this.stop();
      }
    },
    start() {
      this.$refs.video.play().then(() => {
        this.isPlaying = true;
        this.$refs.video.muted = false;
      });
    },
    stop() {
      this.$refs.video.pause()
      this.isPlaying = false;
      this.$refs.video.muted = true;
    }
  },

  watch: {
    routeCounter: {
      handler(newVal, oldVal) {
        if (newVal > oldVal) {
          this.dispose();
        }
      }
    }
  }
});
</script>

<style lang="scss">
.video-player-wrapper {
  &.horizontal {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    video {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &.vertical-fixed {
    width: 100%;
    position: relative;
    background: black;
    height: 100%;

    @media (orientation: portrait) {
      overflow: hidden;
    }

    video {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      height: auto;
      transform: translateX(-50%) translateY(-50%);

      @media (orientation: landscape) {
        max-height: 100%;
      }
    }

    &.adjust-height {
      video {
        height: 100%;
        width: auto;
      }
    }
  }

  .big-play-button {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    background: rgba(var(--ion-color-primary-rgb), 0.5) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Ctitle%3Eplay circle%3C/title%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Cg id='Gruppe_797' data-name='Gruppe 797' transform='translate(-494 -46)'%3E%3Cg id='Gruppe_776' data-name='Gruppe 776' transform='translate(495 47)'%3E%3Cg id='Arrows-Diagrams__x2F__Arrows__x2F__arrow-button-circle-right_1_'%3E%3Cg id='Light_6_1_'%3E%3Cpath id='Shape_6_1_' d='M12.104 23.791a.632.632 0 0 1-.7 0 .555.555 0 0 1-.278-.557V8.904a.723.723 0 0 1 .278-.557.632.632 0 0 1 .7 0l12.526 7.096a.746.746 0 0 1 .417.557c0 .278-.139.417-.417.557z' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3Cpath id='Oval_6_1_' d='M16 32a15.914 15.914 0 0 0 16-16A15.914 15.914 0 0 0 16 0a16 16 0 1 0 0 32z' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center;
    border-radius: 50%;
    background-size: 64px 64px;
    width: 80px;
    height: 80px;
    opacity: 1;
    border: 0;
  }
}

</style>

