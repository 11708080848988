<template>
  <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 165.49 167.42" style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
<switch>
	<g>
		<g>
			<g>
				<path d="M113.45,46.27c-4.11-12.61-15.96-21.75-29.97-21.75c-13.99,0-25.84,9.13-29.97,21.75l29.97,15.04L113.45,46.27z" fill="#ffffff"/>
			</g>
      <path d="M27.77,95.44v-9.65l45.43,22.41v9.63L27.77,95.44z M27.77,72.51v-9.63l45.43,22.39v9.63L27.77,72.51z M22.74,115.42
				l57.17,28.66V66.52L22.74,37.86V115.42z" fill="#ffffff"/>
      <path d="M93.23,85.26l45.42-22.39v9.65L93.23,94.9V85.26z M87.06,66.53v77.56l57.17-28.66V37.87L87.06,66.53z" fill="#ffffff"/>
		</g>
	</g>
</switch>
</svg>
</template>


