
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import BasicPageLayout from '@/views/basic-page-layout.vue';
import SignLanguage from '@/assets/svg/sign-language.vue';
import EasyLanguage from '@/assets/svg/easy-language.vue';
import VideoPlayer from "@/components/video-player.vue";

export default defineComponent({
  extends: BasicPageLayout,

  name: 'RegionTrailerView',
  components: {
    IonButton,
    BasicPageLayout,
    SignLanguage,
    EasyLanguage,
    VideoPlayer,
  },

  data(): any {
    return {
      skipLink: '',
      currentVideoSrc: 'default'
    }
  },

  computed: {
    teaserVideoSources(): object {
      const videoSources = {
        default: '',
        signLanguage: '',
        easyLanguage:  ''
      };

      if (this.regionData && this.regionData['schema:video']) {
        const videoItems = Array.isArray(this.regionData['schema:video'])
          ? this.regionData['schema:video']
          : [this.regionData['schema:video']];

        videoSources.default = ((
          videoItems.find(item => !item['schema:accessibilityFeature'])
        ) || {})['schema:url'] || '';
        videoSources.signLanguage = ((
          videoItems.find(item => (item['schema:accessibilityFeature']
              && item['schema:accessibilityFeature'] === "signLanguage"))
        ) || {})['schema:url'] || '';
        videoSources.easyLanguage = ((
          videoItems.find(item => (item['schema:accessibilityFeature']
              && item['schema:accessibilityFeature'] === "simpleLanguage"))
        ) || {})['schema:url'] || '';
      }
      return videoSources
    },
  },

  methods: {
    switchVideoSource(type) {
      this.currentVideoSrc = type;
      this.$refs.videoPlayer?.$refs.video?.addEventListener('loadedmetadata', () => {
        this.$refs.videoPlayer.start();
      });
    }
  },

  watch: {
    'regionData': {
      immediate: true,
      deep: true,
      handler(newRegionData) {
        if (!newRegionData) {
          return;
        }
        this.currentVideoSrc = 'default';
        this.skipLink = `/regions/${newRegionData.id}/tours`;
      }
    },
  }
})
