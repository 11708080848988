<template>
  <basic-page-layout :noscroll="true">
    <template v-if="regionData">
      <div class="video-overlay-top ion-text-center" slot="fixed">
        <ion-button class="ion-text-capitalize"
                    v-if="currentVideoSrc !== 'default'"
                    @click="switchVideoSource('default')">
          {{ $t('regionTrailer.defaultLanguage') }}
        </ion-button>
        <ion-button class="ion-text-capitalize"
                    v-if="teaserVideoSources['signLanguage'] && currentVideoSrc !=='signLanguage'"
                    @click="switchVideoSource('signLanguage')">
          <SignLanguage/>
          <span>{{ $t('regionTrailer.signLanguage') }}</span>
        </ion-button>
        <ion-button class="ion-text-capitalize"
                    v-if="teaserVideoSources['easyLanguage'] && currentVideoSrc !=='easyLanguage'"
                    @click="switchVideoSource('easyLanguage')">
          <EasyLanguage/>
          <span>{{ $t('regionTrailer.easyLanguage') }}</span>
        </ion-button>
      </div>

      <div class="video-container" slot="fixed">
        <video-player :source="teaserVideoSources[currentVideoSrc]"
                      :autoplay="true"
                      :controls="false"
                      :vertical-fixed="true"
                      ref="videoPlayer"
                      :onEndRedirectTarget="`/regions/${regionData.id}/tours`"
        ></video-player>
      </div>

      <div class="video-overlay-bottom ion-text-center" slot="fixed">
        <ion-button class="ion-text-capitalize skip"
                    v-if="skipLink"
                    @click="navigate(skipLink)">
          {{ $t('regionTrailer.skip') }}
        </ion-button>
      </div>
    </template>
  </basic-page-layout>
</template>

<script lang="ts">
import { IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import BasicPageLayout from '@/views/basic-page-layout.vue';
import SignLanguage from '@/assets/svg/sign-language.vue';
import EasyLanguage from '@/assets/svg/easy-language.vue';
import VideoPlayer from "@/components/video-player.vue";

export default defineComponent({
  extends: BasicPageLayout,

  name: 'RegionTrailerView',
  components: {
    IonButton,
    BasicPageLayout,
    SignLanguage,
    EasyLanguage,
    VideoPlayer,
  },

  data(): any {
    return {
      skipLink: '',
      currentVideoSrc: 'default'
    }
  },

  computed: {
    teaserVideoSources(): object {
      const videoSources = {
        default: '',
        signLanguage: '',
        easyLanguage:  ''
      };

      if (this.regionData && this.regionData['schema:video']) {
        const videoItems = Array.isArray(this.regionData['schema:video'])
          ? this.regionData['schema:video']
          : [this.regionData['schema:video']];

        videoSources.default = ((
          videoItems.find(item => !item['schema:accessibilityFeature'])
        ) || {})['schema:url'] || '';
        videoSources.signLanguage = ((
          videoItems.find(item => (item['schema:accessibilityFeature']
              && item['schema:accessibilityFeature'] === "signLanguage"))
        ) || {})['schema:url'] || '';
        videoSources.easyLanguage = ((
          videoItems.find(item => (item['schema:accessibilityFeature']
              && item['schema:accessibilityFeature'] === "simpleLanguage"))
        ) || {})['schema:url'] || '';
      }
      return videoSources
    },
  },

  methods: {
    switchVideoSource(type) {
      this.currentVideoSrc = type;
      this.$refs.videoPlayer?.$refs.video?.addEventListener('loadedmetadata', () => {
        this.$refs.videoPlayer.start();
      });
    }
  },

  watch: {
    'regionData': {
      immediate: true,
      deep: true,
      handler(newRegionData) {
        if (!newRegionData) {
          return;
        }
        this.currentVideoSrc = 'default';
        this.skipLink = `/regions/${newRegionData.id}/tours`;
      }
    },
  }
})
</script>

<style scoped lang="scss">
ion-button {
  margin: 0;

  &::part(native) {
    border-radius: 0;
  }

  svg {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 0.5em;
  }
}

.video-overlay-top {
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 1;

  ion-button {
    &::part(native) {
      border-radius: 0 0 8px 8px;
    }
  }
}

.video-overlay-bottom {
  bottom: -1px;
  width: 100%;
  z-index: 1;

  ion-button {
    &::part(native) {
      border-radius: 8px 8px 0 0;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.video-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  background: black;
}
</style>


