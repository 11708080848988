<template>
	<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 165.49 167.42" style="enable-background:new 0 0 165.49 167.42;" xml:space="preserve">
<switch>
	<g>
		<g>
			<path d="M87.54,48.75c0,0,0.97-1.62,3.87,2.57c2.9,4.19,2.9,12.56,21.89,19.64c18.99,7.09,29.31,22.55,29.94,30.6
				c0.65,8.05,1.3,17.37-21.24,26.41c-22.54,9-18.36,4.81-27.69,11.25c-9.34,6.44-15.79,6.44-17.39,4.19
				c-1.62-2.27,5.47-6.77,8.69-9.02c3.22-2.27,9.65-4.19,8.37-7.42c-1.3-3.21-13.21,0.65-13.21,0.65l-16.09,4.5
				c0,0-9.67,2.91-10.95-1.28c-1.3-4.19,6.75-6.77,6.75-6.77l18.85-4.83c0,0,4.99-0.65,3.38-4.5c-1.62-3.87-5.47-2.27-5.47-2.27
				s-31.23,4.34-32.53,0c-0.62-7.17,28.66-7.17,28.66-7.17s9.97,0,9.67-3.76c-0.32-3.79-3.55-4.95-7.42-4.95
				c-3.85,0-29.31-0.53-28.98-6.33c0.32-5.8,11.92-4.18,11.92-4.18s40.58,5.47,43.47-4.83c1.6-6.77-4.63-6.57-7.95-9.67
				C90.77,68.52,83.35,61.3,83.68,53.9C84,47.86,87.54,48.75,87.54,48.75" fill="#ffffff"/>
			<path d="M107.51,60.99c0,0-2.25-9.47-4.5-13.12c-2.27-3.63-1.94-4.92-0.97-10.72c0.97-5.8,3.87-12.89,0.97-14.82
				c-2.9-1.93-7.85,0.99-8.92,5.81c-1.07,4.83-2.78,14.8-1.39,16.74C94.08,46.82,104.29,62.61,107.51,60.99" fill="#ffffff"/>
			<path d="M95.27,80.63L80.46,64.2c0,0-2.25-4.5-1.13-11.9L68.54,41.41L57.92,29.43c0,0-8.08-10.02-12.24-7.69
				c-4.5,2.54,1.94,11.24,1.94,11.24S69.83,57.4,67.91,59.26c-1.94,1.85-9.02-3.27-14.17-8.34c-5.17-5.05-19.66-18.67-24.48-14.09
				s16.42,20.29,16.42,20.29s13.21,8.79,12.89,12.24c-0.32,3.46-10.31-2.25-10.31-2.25s-18.34-13.96-25.44-9.08
				c-3.85,3.08,6.77,8.44,6.77,8.44L53.09,81C53.09,81,95.69,82.38,95.27,80.63" fill="#ffffff"/>
			<path d="M44.14,83.7c0,0-4.26,3.04-3.61,6.59c0,0-12.55-2.88-10.95-8.21C31.19,76.77,44.14,83.7,44.14,83.7" fill="#ffffff"/>
		</g>
	</g>
</switch>
</svg>
</template>

